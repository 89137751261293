<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="스케줄 수정"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 일시</th>
                                <td>
                                    <carrot-date-picker v-model="mod.info.vdate" class="dp-inblock mr-5"></carrot-date-picker>
                                    <carrot-time-select v-model="mod.info.vtime" class="ml-10"></carrot-time-select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 목적</th>
                                <td>
                                    <label v-for="(irow, i) in mod.rtype_list" :key="i"><input v-if="irow.code !='마케팅 컨택/제안'" type="radio" v-model="mod.info.rtype" :value="irow.code"><span v-if="irow.code !='마케팅 컨택/제안'" class="ml-5 mr-20"> {{ irow.name}}</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사</th>
                                <td>
                                    <carrot-company v-if="mod.is_loaded" v-model="mod.info.idx_company" class="w-400px"></carrot-company>
                                    <span class="ml-10">* 입력 시 나오는 고객사명에서 선택해주세요. </span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 상세내용</th>
                                <td>
                                    <textarea v-model.trim="mod.info.contents" class="w-100per h-100px"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기타사항<br>(장소 등)</th>
                                <td>
                                    <textarea v-model.trim="mod.info.etc_memo" class="w-100per h-100px"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> Self Driven</th>
                                <td>
                                    <label v-for="(irow, i) in mod.dtype_list" :key="i">
                                        <input type="radio" v-model="mod.info.driven" :value="irow.code">
                                        <span class="ml-5 mr-20"> {{ irow.name}}</span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="mod.doCancel" class="btn-default float-left mt-30 mr-10">취소</button>
                    <button v-if="mod.info.is_owner=='Y'" @click="mod.doDelete" class="btn-default float-left mt-30">삭제</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">저장</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotTimeSelect from '@/components/common/CarrotTimeSelect.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'


export default {
    layout:"customerManagement",
    components: {
        CarrotDatePicker,
        CarrotTimeSelect,
        CarrotCompany
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast();

        const mod = reactive({
            rtype_list : [],
            dtype_list : [],
            is_loaded  : false,

            idx  : 0,
            info : {
                vdate       : "",
                vtime       : "",
                rtype       : [],
                idx_company : 0,
                contents    : "",
                etc_memo    : "",
                driven      : ""
            },

            doCancel : () => {
                router.go(-1);
            },

            doSearchRtype : () => {
                axios.get("/rest/customermgr/getRtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.rtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })

                axios.get("/rest/customermgr/getDtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.dtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doDelete : () => {
                Swal.fire({
                    title : "스케줄 삭제",
                    text: '삭제하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : mod.idx
                        };
                        axios.post("/rest/customermgr/delSchedule", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name: 'customerManagement-scheduleVisitWeekly'
                                })
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx      : mod.idx,
                    vdate    : mod.info.vdate,
                    vtime    : mod.info.vtime,
                    reason   : mod.info.rtype,
                    company  : mod.info.idx_company,
                    contents : mod.info.contents,
                    etc_memo : mod.info.etc_memo,
                    driven   : mod.info.driven
                };

                if( !params.vdate || !params.vtime || !params.reason.length || !params.company || !params.contents  || !params.etc_memo || !params.driven ){
                    toast.error("필수항목을 입력해 주세요.");
                    return;
                }

                axios.post("/rest/customermgr/modSchedule", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title : "수정하였습니다."
                        }).then(() => {
                            router.push({
                                name : 'customerManagement-scheduleVisitView-idx',
                                params : {
                                    idx : res.data.idx
                                }
                            });
                        })
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/customermgr/getScheduleInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        mod.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx?route.params.idx:0;

            if ( !mod.idx ) {
                router.back(-1);
            }

            mod.doSearchRtype();
            mod.doSearch();
        });

        return {mod};
    }
}
</script>

<style lang="scss" scoped>
</style>